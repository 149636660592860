import React from "react";
import { withApollo, compose } from "react-apollo";
import { connect } from "react-redux";
import Popover from "@mui/material/Popover";
import { signOut } from "../utils/signout";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import IconForApp from "../icons/IconForApp";
import { displayNameSelector } from "../features/user/redux/selectors";

import { OwcIcon } from "@one/react/Components/OwcIcon";
import { OwcDoubleGlobalArea } from "@one/react/Components/OwcDoubleGlobalArea";
import { OwcIconButton } from "@one/react/Components/OwcIconButton";
import { OwcTypography } from "@one/react/Components/OwcTypography";
import { OwcTooltip } from "@one/react/Components/OwcTooltip";
import styles from "./AppBar.module.scss";

const Appbar = ({ client, name, email }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const logoLetter = name?.charAt(0);

  const infoInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <OwcDoubleGlobalArea>
        <div slot="primary" style={{ justifyContent: "space-between" }}>
          <div
            style={{
              paddingLeft: "16px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <IconForApp />
            <OwcTypography style={{ paddingLeft: "16px" }} variant="subtitle1">
              Notification
            </OwcTypography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <OwcTypography variant="caption">{name}</OwcTypography>
            <OwcIconButton
              data-testid="app-bar-user-info-user"
              hasFocus={anchorEl !== null}
              onClick={handleClick}
              style={{ marginRight: "8px" }}
              flat
              icon="user"
            />

            <OwcIconButton
              style={{ marginRight: "8px" }}
              flat
              data-testid="info-button"
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              icon="circle_help"
            />

            <OwcIcon
              type="legacy"
              style={{
                margin: "0 8px",
                color: "var(--one-color-interaction-default-brand-1)",
                width: "48px"
              }}
              name="roche_logo"
            />
          </div>
        </div>
      </OwcDoubleGlobalArea>
      {/*  <RocheTopBar
        title={
          <IconWithText
            iconComponent={IconForApp}
            iconStyle={{ marginRight: ".6rem" }}
          >
            <RocheTypography variant="button">Notification</RocheTypography>
          </IconWithText>
        }
        id={"RocheTopBar"}
        rightIcons={[
          <AppBarUserInfo
            data-testid="app-bar-user-info-user"
            button
            hasFocus={anchorEl !== null}
            onClick={handleClick}
          >
            <span className="userNameAppBar" data-cy="app-bar-user-name">
              {name}
            </span>
            <i className="one-icons">user</i>
          </AppBarUserInfo>,
          <AppBarInfo data-testid="App-bar-user-info">
            <IconButton
              data-testid="info-button"
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              classes={{ root: classes.iconButtonRoot }}
            >
              <i className="one-icons">info</i>
            </IconButton>
          </AppBarInfo>
        ]}
      /> */}
      <Popover
        data-testid="popover-user-menu"
        style={{
          minWidth: 218,
          minHeight: 168,
          borderRadius: 4,
          boxShadow: "0px 8px 8px 0px rgba(0,0,0,0.35)"
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div className={styles.UserInfoSection} data-testid="popover-user-menu-info-section">
          <div className={styles.UserLogo} data-testid="popover-user-menu-info-section-logo">
            {logoLetter}
          </div>
          <div id="div_appBar_name" className={styles.UserName} data-testid="popover-user-menu-info-section-username">
            {name}
          </div>
          <OwcTooltip style={{ zIndex: 11000 }} anchor="div_appBar_name" autoUnportal>
            {name}
          </OwcTooltip>
          <div id="div_appBar_email" className={styles.UserEmail} data-testid="popover-user-menu-info-section-email">
            {email}
          </div>
          <OwcTooltip style={{ zIndex: 11000 }} anchor="div_appBar_email" autoUnportal>
            {email}
          </OwcTooltip>
          <hr />
        </div>
        <MenuList className={styles.popoverMenuList} data-testid="popover-user-menu-footer-section">
          <MenuItem
            data-testid="popover-user-menu-footer-section-logout"
            onClick={() => {
              handleClose();
              signOut(client);
            }}
          >
            <OwcIcon
              type="legacy"
              style={{
                color: "var(--one-color-foreground-base-1)",
                marginRight: "17px"
              }}
              name="log_off"
            />
            Log out
          </MenuItem>
        </MenuList>
      </Popover>
      <div />
    </>
  );
};

const mapStateToProps = (store) => ({
  name: displayNameSelector(store),
  email: store.user.email
});

export default compose(connect(mapStateToProps), withApollo)(Appbar);
