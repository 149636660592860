import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";

import { OwcBanner } from "@one/react/Components/OwcBanner";

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: "toast-container",
  toastClassName: "toast",
  bodyClassName: "body",
  progressClassName: "progress"
})`
  height: 72px;
  width: 400px;
  border-radius: 4px;

  .toast {
    top: 56px;
    padding: 0;
    box-sizing: border-box;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.34902);
  }

  button[aria-label="close"] {
    display: none;
  }

  .body {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .progress {
    background: transparent;
  }
`;

export const CustomNotification = ({ type, icon, text, appName }) => (
  <>
    <OwcBanner data-testid="notify" dismissible type={type}>
      <div className="text" data-testid="notify-text">
        <strong data-testid="notify-name-text">{appName}</strong> {text}
      </div>
    </OwcBanner>
  </>
);

export const Notify = ({ icon = "caution", text = "", appName = "Instrument name", type = "warning" }) =>
  toast(<CustomNotification {...{ icon, text, appName, type }} />);

export default Notify;
