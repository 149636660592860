import React from "react";
import styled, { css } from "styled-components";
import { DEFAULT_DESCRIPTION_CONSTANT } from "../../constants";
import { getHashCommitValue } from "../../utils/helpers/text";
import { useMobile } from "../../utils/hooks/useMobile";
import { changeDateFormat } from "../../utils/helpers/text";

export const ModuleVersionInfoStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(auto, 70%);
  width: 80%;
  max-width: 800px;
  & > p:first-child {
    grid-row: 1;
  }
  grid-gap: 10px;
  white-space: wrap;
  text-overflow: ellipsis;
  text-align: left;
  padding-left: 8px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 16px;
  align-items: flex-start;
  background: white;
  border-radius: 4px;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-bottom: 20px;
  & > p {
    margin: 0;
    padding: 8px;
  }
  & > a {
    text-decoration: none;
    color: #888;
    font-weight: 500;
    text-align: left;
    margin: 0;
    padding: 8px;
  }
  & > a > p {
    margin: 0;
  }

  & > p[data-testid$="text"] {
    font-weight: 500;
    text-align: left;
  }
  & > p.title {
    grid-row: 1;
    display: grid;
    grid-column: span 2;
    text-align: left;
    border-bottom: 1px solid #eee;
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 15px;
    ${(props) =>
      props.isMobile &&
      css`
        text-align: center;
        font-size: 14px;
        line-height: 14px;
        padding-bottom: 10px;
      `}
  }
  ${(props) =>
    props.isMobile &&
    css`
      font-size: 13px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem;
      width: 90%;
      & > p {
        padding: 0;
        width: 100%;
      }
      & > p[data-testid$="text"] {
        padding: 0 0 5px 0;
        &: not(: last-child) {
          border-bottom: 1px solid #eee;
        }
      }
    `}
`;

const DescriptionCard = ({
  title = "Information",
  moduleVersion = process.env.REACT_APP_MODULE_VERSION,
  moduleName = process.env.REACT_APP_MODULE_NAME,
  ciJobId = process.env.REACT_APP_CI_JOB_ID,
  commitHash = process.env.REACT_APP_COMMIT_HASH,
  updatedAt = process.env.REACT_APP_UPDATED_AT
}) => {
  const isMobile = useMobile();
  return (
    <ModuleVersionInfoStyled data-testid="info-page-container" isMobile={isMobile}>
      <p className="title">{title}</p>
      <p data-testid="info-page-app-name-label">App name:</p>
      <p data-testid="info-page-app-name-text">{moduleName ?? DEFAULT_DESCRIPTION_CONSTANT.moduleName}</p>
      <p data-testid="info-page-module-version-label">System version:</p>
      <p data-testid="info-page-module-version-text">{moduleVersion ?? DEFAULT_DESCRIPTION_CONSTANT.moduleVersion}</p>
      <p data-testid="info-page-ci-job-label">CI job id:</p>
      <a href={ciJobId} target="_blank" rel="noreferrer">
        <p data-testid="info-page-ci-job-text">
          {ciJobId ? getHashCommitValue(ciJobId) : DEFAULT_DESCRIPTION_CONSTANT.ciJobId}
        </p>
      </a>
      <p data-testid="info-page-commit-hash-label">Commit hash:</p>
      <a href={commitHash} target="_blank" rel="noreferrer">
        <p data-testid="info-page-commit-hash-text">
          {commitHash ? getHashCommitValue(commitHash) : DEFAULT_DESCRIPTION_CONSTANT.commitHash}
        </p>
      </a>
      <p data-testid="info-page-update-at-label">Updated at:</p>
      <p data-testid="info-page-update-at-text">
        {updatedAt ? changeDateFormat(updatedAt, "mm/dd/yyyy h:mm:ss") : DEFAULT_DESCRIPTION_CONSTANT.updatedAt}
      </p>
    </ModuleVersionInfoStyled>
  );
};

export default DescriptionCard;
