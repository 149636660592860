import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { Auth } from "aws-amplify";
import { OwcProgressSpinner } from "@one/react/Components/OwcProgressSpinner";
import { loadUserInfo as loadUserInfoAction } from "../user/redux/actions";
import styled from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { signOut } from "../../utils/signout";

import { OwcTypography } from "@one/react/Components/OwcTypography";
import { OwcButton } from "@one/react/Components/OwcButton";
import { NOTIFICATION_USER, GROUPS_TOKEN_PATH, ACCESS_TOKEN_PATH, SYNC_PROFILE_FIELDS } from "../../constants";
import {
  CREATE_DIGITAL_LAB_NOTIFICATION_USER_PROFILE,
  UPDATE_DIGITAL_LAB_NOTIFICATION_USER_PROFILE_PERSONAL_FIELDS,
  UPDATE_PHONE_NUMBER
} from "../../gql/notifications/mutations";
import { GET_LOGGED_USER_DETAILS } from "../../gql/notifications/queries";
import { isEqual, pick } from "underscore";
import Notify from "../notifications-compontent/Notify";
import { useMatomo } from "@datapunt/matomo-tracker-react";

export const FullScreenCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100vw;
  height: 100vh;
`;
class SiteError extends Error {
  constructor(message) {
    super(message);
    this.name = "SiteError";
  }
}

const stripPhoneNumber = (phoneNumber) => phoneNumber?.replace(/[^+\d]/gi, "") ?? "";

const checkRoleAndGetUserEmail = async (required_group = NOTIFICATION_USER) => {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
  const access_groups = get(currentAuthenticatedUser, GROUPS_TOKEN_PATH);
  if (!Array.isArray(access_groups) || !access_groups.includes(required_group)) {
    throw new Error("You do not have permission to access.");
  }
  const syncFields = pick(get(currentAuthenticatedUser, ACCESS_TOKEN_PATH), Object.values(SYNC_PROFILE_FIELDS));
  const email = get(currentAuthenticatedUser, "attributes.email");
  if (syncFields?.[SYNC_PROFILE_FIELDS.phone]) {
    syncFields[SYNC_PROFILE_FIELDS.phone] = stripPhoneNumber(syncFields?.[SYNC_PROFILE_FIELDS.phone]);
  }
  return { email, ...syncFields };
};

export const initializeUserMobileNumber = async ({ client, user }) => {
  let status = null;
  try {
    status = JSON.parse(user?.status);
  } catch (err) {
    status = null;
  }
  console.info("@@@ user:", {
    user,
    status
  });

  // check if user profile fetched phone, if so, no  execute further steps
  if (status?.initalMobilePhoneSetup) {
    return user;
  }
  const {
    data: { updatePhoneNumber }
  } = await client.mutate({
    mutation: UPDATE_PHONE_NUMBER,
    fetchPolicy: "no-cache"
  });

  if (updatePhoneNumber) {
    return { ...user, ...updatePhoneNumber };
  }
  return user;
};

export const updateProfileFields = async ({ client, email, user, ...syncFields }) => {
  const syncKeys = Object.keys(SYNC_PROFILE_FIELDS);
  const profileObj = syncKeys.reduce(
    (acc, currKey) => ({
      ...acc,
      [currKey]: syncFields?.[SYNC_PROFILE_FIELDS?.[currKey]] ?? ""
    }),
    {}
  );
  if (!user) {
    return profileObj;
  }
  const shouldUpdate = !isEqual(pick(user, syncKeys), {
    ...profileObj,
    site: profileObj?.site || user.site
  });
  if (!shouldUpdate) {
    return user;
  }
  const {
    data: { updateDigitalLabNotificationsUserProfile: updatedUser }
  } = await client.mutate({
    mutation: UPDATE_DIGITAL_LAB_NOTIFICATION_USER_PROFILE_PERSONAL_FIELDS,
    fetchPolicy: "no-cache",
    variables: {
      id: user?.id,
      email,
      ...profileObj,
      site: profileObj?.site || user?.site
    }
  });
  return updatedUser;
};

export const getUserDetails = async ({ client, email, ...syncFields }) => {
  const result = await client.query({
    query: GET_LOGGED_USER_DETAILS,
    fetchPolicy: "no-cache",
    variables: {
      email
    }
  });

  const user = get(result, "data.getLoggedInUserDetails.items[0]");
  if (!syncFields?.[SYNC_PROFILE_FIELDS.site] && !user?.site) {
    throw new SiteError("You do not have site location assigned. Go to the Landing Page to configure this.");
  }
  if (!syncFields?.[SYNC_PROFILE_FIELDS.site]) {
    Notify({
      type: "warning",
      icon: "caution",
      appName: "",
      text: "Your location has been changed. Please check your settings at the Landing Page."
    });
  }
  const profileFieldsOrUpdatedUser = await updateProfileFields({
    client,
    user,
    email,
    ...syncFields
  });
  let _user = null;
  if (profileFieldsOrUpdatedUser?.id) {
    _user = await initializeUserMobileNumber({
      client,
      user: { ...user, ...profileFieldsOrUpdatedUser }
    });
    return { user: _user };
  }
  const {
    data: { createDigitalLabNotificationsUserProfile }
  } = await client.mutate({
    mutation: CREATE_DIGITAL_LAB_NOTIFICATION_USER_PROFILE,
    fetchPolicy: "no-cache",
    variables: {
      email,
      ...profileFieldsOrUpdatedUser
    }
  });

  _user = await initializeUserMobileNumber({
    client,
    user: createDigitalLabNotificationsUserProfile
  });

  return {
    user: _user
  };
};

const LoadNotificationInfo = ({ children, client, loadUserInfo, loadNotification }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);

  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView({
      documentTitle: "Notification",
      href: `${window.location?.origin}/notification`
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      setError(null);
      try {
        const { email, ...syncFields } = await checkRoleAndGetUserEmail();
        const { user } = await getUserDetails({ client, email, ...syncFields });
        loadUserInfo({
          ...user
        });
      } catch (err) {
        console.warn(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [loadUserInfo, loadNotification, client, retry]);
  if (loading) {
    return (
      <FullScreenCentered>
        <OwcProgressSpinner data-testid="loading" diameter={80} />
      </FullScreenCentered>
    );
  }
  if (error !== null) {
    return (
      <FullScreenCentered>
        <>
          <OwcTypography variant="h6" data-testid="message-error" gutterBottom>
            {error?.message ? error?.message : "Unexpected error has occurred"}
          </OwcTypography>
          {error?.name !== "SiteError" && (
            <OwcButton onClick={() => setRetry(retry + 1)} startIcon={<ErrorIcon />} data-testid="try-again-button">
              Try again
            </OwcButton>
          )}
          <OwcButton
            data-testid="signout-button"
            variant="contained"
            style={{ marginTop: ".7rem" }}
            onClick={() => signOut()}
            startIcon={<ExitToAppIcon />}
          >
            Sign out
          </OwcButton>
        </>
      </FullScreenCentered>
    );
  }
  return children;
};

export default compose(
  connect(null, {
    loadUserInfo: loadUserInfoAction
  }),
  withApollo
)(LoadNotificationInfo);
