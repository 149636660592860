import React from "react";
import Slide from "@mui/material/Slide";
import { OwcModalDialog } from "@one/react/Components/OwcModalDialog";
import { OwcTypography } from "@one/react/Components/OwcTypography"; // other import way
import { OwcProgressSpinner } from "@one/react/Components/OwcProgressSpinner";
import { OwcButton } from "@one/react/Components/OwcButton";
import { OwcIcon } from "@one/react";
import { CONTENT_MESSAGE_WITH_ICON } from "../../constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfirmDialog = ({
  testid = "confirm-dialog",
  title = "",
  content = "",
  cancelText,
  approveText,
  cancelVariant,
  cancelColor,
  approveColor,
  approveVariant,
  onCancel,
  onApprove,
  close,
  open,
  children,
  renderActions,
  isDivider = false,
  loading = false
}) => {
  const handleCancel = () => {
    close();
    if (onCancel) {
      onCancel();
    }
  };
  const handleApprove = () => {
    close();
    if (onApprove) {
      onApprove();
    }
  };

  return (
    <>
      <OwcModalDialog
        size="sm"
        visible={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancel}
        data-testid={testid}
      >
        <div data-testid="confirm-dialog-title" slot="header">
          {title}
        </div>
        {isDivider && <hr data-testid="divider" />}
        <div data-testid="confirm-dialog-content">
          {children ? (
            children
          ) : (
            <div data-testid="confirm-dialog-content-text" s style={{ display: "flex", alignItems: "flex-start" }}>
              {content === CONTENT_MESSAGE_WITH_ICON && (
                <OwcIcon name="circle_info" type="legacy" style={{ marginRight: 10 }} />
              )}
              <OwcTypography style={{ alignItems: "left" }} variant="subtitle1">
                {content}
              </OwcTypography>
            </div>
          )}
        </div>
        <div
          slot="actions"
          style={{ display: "flex", justifyContent: "flex-end" }}
          data-testid="confirm-dialog-actions"
        >
          {typeof renderActions === "function" ? (
            renderActions({
              cancelColor,
              cancelVariant,
              approveColor,
              approveVariant,
              cancelText,
              approveText,
              close
            })
          ) : (
            <>
              <OwcButton
                data-testid="confirm-dialog-actions-button-cancel"
                onClick={handleCancel}
                variant="secondary"
                style={{ marginLeft: 24 }}
              >
                {cancelText || null}
              </OwcButton>
              <OwcButton
                data-testid="confirm-dialog-actions-button-approve"
                onClick={handleApprove}
                disabled={loading}
                style={{ marginLeft: 24 }}
              >
                {loading && <OwcProgressSpinner color="inherit" diameter={20} style={{ marginRight: 12 }} />}{" "}
                {approveText || null}
              </OwcButton>
            </>
          )}
        </div>
      </OwcModalDialog>
    </>
  );
};
export default ConfirmDialog;
