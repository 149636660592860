import { CHANNEL_TYPES, SEVERITY_THRESHOLDS } from "../../../constants";
import { merge, differenceWith, isEqual, flatten } from "lodash";
import { omit } from "underscore";

const getAllValues = (apps) => {
  return apps?.reduce((acc, app) => {
    return {
      ...acc,
      ...Object.keys(CHANNEL_TYPES).reduce(
        (accChannel, channel) => ({
          ...accChannel,
          [`${app.source}#${channel}`]: {
            source: app?.source,
            channel,
            severityThreshold:
              CHANNEL_TYPES.LandingPage.value === channel
                ? SEVERITY_THRESHOLDS.NOT_DEFINED.value
                : SEVERITY_THRESHOLDS.DISABLED.value
          }
        }),
        {}
      )
    };
  }, {});
};
const getValues = (subs) =>
  subs.reduce(
    (acc, sub) => ({
      ...acc,
      [`${sub.source}#${sub.channel}`]: omit(sub, "__typename")
    }),
    {}
  );

export const getInitialValues = (apps, subcriptions) => {
  const appsMap = apps.reduce((acc, app) => ({ ...acc, [app?.source]: app?.displayName }), {});
  const valByKey = merge(getAllValues(apps), getValues(subcriptions));
  const rowMap = Object.keys(valByKey).reduce(
    (acc, key) =>
      valByKey[key].source in acc
        ? {
            ...acc,
            [valByKey[key].source]: {
              ...acc[valByKey[key].source],
              [key]: valByKey[key]
            }
          }
        : {
            ...acc,
            [valByKey[key].source]: {
              [key]: valByKey[key]
            }
          },
    {}
  );

  return Object.keys(rowMap).reduce(
    (acc, appKey) => [...acc, { id: appKey, displayName: appsMap?.[appKey], ...rowMap[appKey] }],
    []
  );
};

export const getDiffValues = (val1, val2) => {
  const flatDiff = (arr) => flatten(arr.map(({ id, ...x }) => Object.values(x)));
  const diffRows1 = differenceWith(val1, val2, isEqual);
  const diffRows2 = differenceWith(val2, val1, isEqual);
  return differenceWith(flatDiff(diffRows2), flatDiff(diffRows1), isEqual);
};
