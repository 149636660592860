const NoConnectionIcon = (props) => (
  <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M30.8 86.9C17.5 85.5 7 74.1 7 60.2C7 46.4 17.4 35 30.6 33.7L32.7 33.5L33.7 31.6C39.5 20.5 50.8 13 64 13C80.6 13 94.2 24.9 97.4 40.8L98 43.8L101.1 44C106.5 44.3 111.5 46.7 115.2 50.7C118.9 54.7 121 59.9 120.9 65.4C120.9 77.3 111.2 87 99.5 87H97.1"
      fill="white"
    />
    <path
      d="M34.8 86.9C21.5 85.5 11 74.1 11 60.2C11 46.4 21.4 35 34.6 33.7L36.7 33.5L37.7 31.6C43.5 20.5 54.8 13 68 13C84.6 13 98.2 24.9 101.4 40.8L102 43.8L105.1 44C110.5 44.3 115.5 46.7 119.2 50.7C122.9 54.7 125 59.9 124.9 65.4C124.9 77.3 115.2 87 103.5 87H101.1"
      fill="#E0ECF9"
    />
    <path d="M65 20.5007C65 20.5007 71.5 20.0016 75 22.0007" stroke="white" stroke-width="2" />
    <path
      d="M30.8 86.9C17.5 85.5 7 74.1 7 60.2C7 46.4 17.4 35 30.6 33.7L32.7 33.5L33.7 31.6C39.5 20.5 50.8 13 64 13C80.6 13 94.2 24.9 97.4 40.8L98 43.8L101.1 44C106.5 44.3 111.5 46.7 115.2 50.7C118.9 54.7 121 59.9 120.9 65.4C120.9 77.3 111.2 87 99.5 87L30.8 86.9Z"
      stroke="#80B3E6"
      stroke-width="2"
    />
    <path d="M20.5 98L13 105.5L20.5 106.5L13 114" stroke="#80B3E6" stroke-width="2" />
    <path d="M109.025 98.5406L113.499 110.032L105.049 107.588L109.523 119.079" stroke="#80B3E6" stroke-width="2" />
    <circle cx="116" cy="15" r="4" fill="white" stroke="#80B3E6" stroke-width="2" />
    <rect
      x="18.297"
      y="2.22474"
      width="8"
      height="8"
      transform="rotate(15 18.297 2.22474)"
      fill="white"
      stroke="#80B3E6"
      stroke-width="2"
    />
    <path d="M81.4118 70H56.1765L47 99.8723H61.9118L53.8824 124L86 87.234H71.0882L81.4118 70Z" fill="white" />
    <path
      d="M56.1765 70V69H55.4375L55.2206 69.7064L56.1765 70ZM81.4118 70L82.2696 70.5139L83.1765 69H81.4118V70ZM71.0882 87.234L70.2304 86.7202L69.3235 88.234H71.0882V87.234ZM86 87.234L86.7531 87.8919L88.2014 86.234H86V87.234ZM53.8824 124L52.9335 123.684L54.6355 124.658L53.8824 124ZM61.9118 99.8723L62.8606 100.188L63.2985 98.8723H61.9118V99.8723ZM47 99.8723L46.0441 99.5787L45.6467 100.872H47V99.8723ZM56.1765 71H81.4118V69H56.1765V71ZM80.5539 69.4861L70.2304 86.7202L71.9461 87.7479L82.2696 70.5139L80.5539 69.4861ZM71.0882 88.234H86V86.234H71.0882V88.234ZM85.2469 86.5761L53.1292 123.342L54.6355 124.658L86.7531 87.8919L85.2469 86.5761ZM54.8312 124.316L62.8606 100.188L60.9629 99.5566L52.9335 123.684L54.8312 124.316ZM61.9118 98.8723H47V100.872H61.9118V98.8723ZM47.9559 100.166L57.1324 70.2936L55.2206 69.7064L46.0441 99.5787L47.9559 100.166Z"
      fill="#80B3E6"
    />
    <path d="M46.0007 29.5272C48.9995 24.5276 53 20.5269 61.9999 20.5271" stroke="white" stroke-width="2" />
  </svg>
);
export default NoConnectionIcon;
