import styled from "styled-components";
import { OwcButton } from "@one/react";
import { OwcTypography } from "@one/react/Components/OwcTypography";
const InfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

export const InfoWithCallback = ({
  title = "Cannot fetch data",
  buttonLabel = "Retry",
  callback,
  containerProps = null,
  titleProps = null,
  buttonProps = null
}) => {
  return (
    <InfoStyled data-testid="InfoWithCallback-container" {...containerProps}>
      <OwcTypography data-testid="InfoWithCallback-title" variant="h6" gutterBottom {...titleProps}>
        {title}
      </OwcTypography>
      <OwcButton
        data-testid="InfoWithCallback-button"
        variant="secondary"
        onClick={() => typeof callback === "function" && callback()}
        {...buttonProps}
      >
        {buttonLabel}
      </OwcButton>
    </InfoStyled>
  );
};
