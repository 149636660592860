import React, { useContext } from "react";
import DescriptionCard from "./DescriptionCard";
import { BackendInfoContext } from "../../features/backend-info-context/context";
import { DEFAULT_DESCRIPTION_CONSTANT } from "../../constants";
const ReturnItems = ({ items }) => {
  return items?.map((item, id) =>
    item ? <DescriptionCard title={`Backend ${id + 1}`} key={item?.id} {...item} /> : null
  );
};

const BackendInfo = () => {
  const { backendInfo } = useContext(BackendInfoContext);
  const defaultConstantValue = { ...DEFAULT_DESCRIPTION_CONSTANT };
  delete defaultConstantValue?.updatedAt;
  const backendData = backendInfo ? [backendInfo] : [defaultConstantValue];
  return (
    <>
      <ReturnItems items={backendData} />
    </>
  );
};

export default BackendInfo;
