import gql from "graphql-tag";

export const GET_LOGGED_USER_DETAILS = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        id
        email
        familyName
        givenName
        name
        phone
        mobilePhone
        site
        user
        status
      }
    }
  }
`;

export const LIST_APPLICATION_SOURCES = gql`
  query listDigitalLabNotificationsSources {
    listDigitalLabNotificationsSources {
      items {
        displayName
        source
      }
      nextToken
    }
  }
`;

export const LIST_NOTIFICATIONS_SOURCE_TYPE_BY_USER_CHANNEL = gql`
  query listDigitalLabNotificationsSourceTypeFilterForUserChannels($limit: Int, $nextToken: String) {
    listDigitalLabNotificationsSourceTypeFilterForUserChannels(limit: $limit, nextToken: $nextToken) {
      items {
        channel
        source
        severityThreshold
      }
      nextToken
    }
  }
`;

export const LIST_BACKEND_HEALTH_CHECKS = gql`
  query listBackendHealthChecks {
    listBackendHealthChecks {
      items {
        ciJobId
        commitHash
        createdAt
        id
        moduleName
        moduleVersion
        enableAnalyticsTracking
        updatedAt
      }
    }
  }
`;
