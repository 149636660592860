import React, { useContext } from "react";
import { withApollo } from "react-apollo";
import { SubscriptionSettingsContext } from "../context/context";
import { useSubscriptionData } from "./useSubscriptionData";
import { InfoWithCallback } from "../../../components/shared";
import { useOnLeave } from "../../../utils/hooks/useOnLeave";
import NotificationMainPage from "../NotificationMainPage";

const NotificationSubscriptionsList = ({ client }) => {
  const { data, changes } = useContext(SubscriptionSettingsContext);
  useOnLeave({
    shouldConfirm: !!changes.length
  });
  const { loading, error, refreshData } = useSubscriptionData({
    client
  });
  if (error) {
    return <InfoWithCallback callback={() => refreshData()} />;
  }
  return (
    <>
      <div data-testid="notification-settings-table" style={{ width: "100%", boxSizing: "border-box" }}>
        <NotificationMainPage data={data} loading={loading} />
      </div>
    </>
  );
};

export default withApollo(NotificationSubscriptionsList);
