import React from "react";
import { useHistory } from "react-router";
import NotFoundIcon from "../icons/NotFoundIcon";
import styles from "./NotFoundScreen.module.scss";
import { OwcButton } from "@one/react/Components/OwcButton"; // other import way
import { OwcTypography } from "@one/react/Components/OwcTypography";

const NotFoundScreen = ({
  text = "Sorry, we can't find page you are looking for.",
  title = "Page not found",
  redirectPath = "/"
}) => {
  const history = useHistory();
  return (
    <div className={styles.ErrorScreen} data-testid="main-page-not-authenticated-with-error-not-found">
      <NotFoundIcon />
      <OwcTypography style={{ color: "inherit" }} element="h2" variant="title2">
        {title}
      </OwcTypography>
      <OwcTypography style={{ color: "inherit" }} element="h5" variant="title5">
        {text}
      </OwcTypography>

      <OwcButton
        data-testid="main-page-action-button-not-found"
        variant="primary"
        onClick={() => {
          history?.replace(redirectPath);
        }}
      >
        Go to home
      </OwcButton>
    </div>
  );
};

export default NotFoundScreen;
